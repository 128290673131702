import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { Index } from "pages/Index";
import { Links } from "pages/Links";
import { PageNotFound } from "pages/PageNotFound";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Index />,
    errorElement: <PageNotFound />,
    children: [
      {
        path: "links",
        element: <Links />,
      },
    ],
  },
]);
