import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "app/store";

export type PageState = {
  mode: "normal" | "dank";
};

const initialState: PageState = {
  mode: "normal",
};

export const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    toggle: (state) => {
      return {
        mode: state.mode === "normal" ? "dank" : "normal",
      };
    },
  },
});

export const { toggle } = pageSlice.actions;

export const selectPageMode = (state: RootState) => state.page.mode;

export default pageSlice.reducer;
