export type SocialMedia =
  | "tiktok"
  | "twitch"
  | "youtube"
  | "discord"
  | "instagram";

export const ORIGIN = new URL("https://www.bisbeebuds.tv");

export const URLs: Record<SocialMedia, URL> = {
  tiktok: new URL(process.env.REACT_APP_TIKTOK_URL ?? ORIGIN),
  twitch: new URL(process.env.REACT_APP_TWITCH_URL ?? ORIGIN),
  youtube: new URL(process.env.REACT_APP_YOUTUBE_URL ?? ORIGIN),
  discord: new URL(process.env.REACT_APP_DISCORD_URL ?? ORIGIN),
  instagram: new URL(process.env.REACT_APP_INSTAGRAM_URL ?? ORIGIN),
} as const;

export enum Names {
  tiktok = "TikTok",
  twitch = "Twitch",
  youtube = "YouTube",
  discord = "Discord",
  instagram = "Instagram",
}
