import type { HTMLAttributes, ReactNode } from "react";
import omit from "lodash/omit";
import cn from "classnames";

type Attributes = HTMLAttributes<HTMLHeadingElement>;
type Props = {
  children?: ReactNode;
} & Attributes;
export function BrandHeading(props: Props) {
  const attributes: Attributes = omit(props, ["children", "className"]);
  return (
    <h1 className={cn("theme-brand-title", props.className)} {...attributes}>
      {props.children}
    </h1>
  );
}
