import type { ReactNode, HTMLAttributes } from "react";
import type { HeadingTypes } from "utils/types";
import omit from "lodash/omit";
import cn from "classnames";

type Attributes = HTMLAttributes<HTMLHeadingElement>;
type Props = {
  children?: ReactNode;
  type: HeadingTypes;
} & Attributes;

export function Heading(props: Props) {
  const attributes: Attributes = omit(props, ["children", "className"]);
  return (
    <props.type
      className={cn("theme-light-text theme-text-shadow", props.className)}
      {...attributes}
    >
      {props.children}
    </props.type>
  );
}
