import type { ReactNode, ReactElement } from "react";
import { cloneElement } from "react";
import "./SocialLink.css";

export type Props = {
  children?: ReactNode;
  url: URL;
  logo: ReactElement;
};

export function SocialLink(props: Props) {
  return (
    <a href={props.url.href} className="social-link">
      {cloneElement(props.logo, { className: "social-link-logo" })}
      <p className="social-link-text">{props.children}</p>
      {cloneElement(props.logo, { className: "social-link-logo hidden" })}
    </a>
  );
}
