import { Link } from "react-router-dom";
import { BrandHeading } from "components/Copy/BrandHeading";
import { Heading } from "../components/Copy/Heading";
import "./PageNotFound.css";

export function PageNotFound() {
  return (
    <div
      id="page-not-found"
      className="website-background fill-screen center-children center-text"
    >
      <BrandHeading>Bisbee Buds</BrandHeading>
      <Heading type="h2">
        Page not found. <Link to="/">Click here</Link> to come back home.
      </Heading>
    </div>
  );
}
