import type { ReactNode, HTMLAttributes } from "react";
import omit from "lodash/omit";
import cn from "classnames";

type Attributes = HTMLAttributes<HTMLParagraphElement>;
type Props = {
  children?: ReactNode;
} & Attributes;

export function Body(props: Props) {
  const attributes: Attributes = omit(props, ["children", "className"]);
  return (
    <p
      className={cn("theme-light-text theme-text-shadow", props.className)}
      {...attributes}
    >
      {props.children}
    </p>
  );
}
