import "assets/styles/styles.css";
import { Links } from "./Links";
import { useAppSelector } from "app/hooks";
import { selectPageMode } from "features/page/pageSlice";
import "./Index.css";

export function Index() {
  const mode = useAppSelector(selectPageMode);

  return (
    <div
      id="index"
      className={`website-background${mode === "dank" ? " dank-mode" : ""}`}
      data-testid="index"
    >
      <Links />
    </div>
  );
}
