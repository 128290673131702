import { useState, useEffect } from "react";
import { SocialLink } from "components/SocialLink";
import { Names, URLs } from "../constants";
import profileImage from "assets/images/james-brown.jpg";
import { TikTok } from "components/logos/TikTok";
import { YouTube } from "components/logos/YouTube";
import { Twitch } from "components/logos/Twitch";
import { Instagram } from "components/logos/Instagram";
import { Discord } from "components/logos/Discord";
import { BrandHeading } from "components/Copy/BrandHeading";
import { Body } from "components/Copy/Body";
import dankSong from "assets/audio/dank-song.mp3";
import dankBackground from "assets/images/weed-icon.jpg";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { selectPageMode, toggle } from "features/page/pageSlice";
import "./Links.css";

const clickMe = `"Click me!"`;

export function Links() {
  const mode = useAppSelector(selectPageMode);
  const dispatch = useAppDispatch();
  const [song] = useState<HTMLAudioElement>(new Audio(dankSong));
  const isNormalMode = mode === "normal";

  useEffect(() => {
    song.volume = 0.5;
  }, [song]);

  useEffect(() => {
    const img = new Image();
    img.src = dankBackground;
  }, []);

  const handleDankButtonClick = () => {
    isNormalMode ? song.play() : song.pause();
    dispatch(toggle());
  };

  return (
    <div id="links">
      <div className="links__content">
        <div className="links__image-container">
          <Body className=" links__click-me hidden">{clickMe}</Body>
          <img
            src={profileImage}
            className="links__profile-image"
            alt="Bisbee Buds"
            onClick={handleDankButtonClick}
          />
          <Body className="links__click-me">{clickMe}</Body>
        </div>

        <div className="links__title-container">
          <BrandHeading>Bisbee Buds</BrandHeading>
          <p>Here are all of our links!</p>
        </div>
        <div className="links__link-container">
          <SocialLink logo={<TikTok />} url={URLs.tiktok}>
            {Names.tiktok}
          </SocialLink>
          <SocialLink logo={<Twitch />} url={URLs.twitch}>
            {Names.twitch}
          </SocialLink>
          <SocialLink logo={<YouTube />} url={URLs.youtube}>
            {Names.youtube}
          </SocialLink>
          <SocialLink logo={<Discord />} url={URLs.discord}>
            {Names.discord}
          </SocialLink>
          <SocialLink logo={<Instagram />} url={URLs.instagram}>
            {Names.instagram}
          </SocialLink>
        </div>
      </div>
    </div>
  );
}
